import React, { useState, useEffect, useContext } from 'react';
import CreateProposalForm from './CreateProposalForm';
import { useAddress } from "@thirdweb-dev/react";
import { BeatLoader } from "react-spinners";
import { useContractWrite, useContract} from "@thirdweb-dev/react";
import { UserDiv, fetchKeyUsers } from '../utils/KeyUsers';
import { FTaddress } from '../utils/address';
import { GlobalContext } from '../context/GlobalContext';
import { EligibleProposalsContext } from '../context/EligibleProposalsContext';
import { useIsWlKeyHolder } from '../utils/sc_interactions';
import {DarkModeContext} from '../context/DarkModeContext';




const contractAddress = FTaddress;

function CreateProposal() {
  const [isCreating, setIsCreating] = useState(false);
  const userAddress = useAddress();
  const [needsKey, setNeedsKey] = useState(false);
  const [isLoadingModal, setIsLoading] = useState(false);
  const [errorBuy, setError] = useState(null);
  const { contract } = useContract(contractAddress);
  const [ data ] = useIsWlKeyHolder(userAddress);
  const [users, setUsers] = useState([]);
  const {showGlobal, setShowGlobal} = useContext(GlobalContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {isLoading } = useContractWrite(
    contract,
    "buyShares",
  );
  const { eligibleProposals } = useContext(EligibleProposalsContext);
  const { isDarkMode } = useContext(DarkModeContext);


  useEffect(() => {
    fetchKeyUsers(setError).then(setUsers);
  }, []);

  useEffect(() => {
    if (userAddress) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userAddress]);


  const handleCreateClick = async () => {
    setIsLoading(true);
    console.log(data);
    let userHasKey = false;
    if (data) {
      userHasKey = true;
    }

    

  if (userHasKey) {
      setIsCreating(true);
      setIsLoading(false);
    } else {
      setNeedsKey(true);
      setIsLoading(false);
      console.error('Not owning Key');
    }
  };



  const handleSubmit = (proposal) => {
    console.log(proposal);
    setIsCreating(false);
  };



  
  

  
  return (
    <GlobalContext.Provider value={{ showGlobal, setShowGlobal }}>
      <div style={{  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: isDarkMode ? '#161618' : '#fff', color: isDarkMode ? '#fff' : '#000' }}>
        
        {userAddress && (
          <button className='CreateProposalButton' onClick={handleCreateClick}>
            {isLoading ? (
              <BeatLoader color={"#fff"} loading={isLoadingModal} size={15} />
            ) : (
              "+ Create Proposal"
            )}
          </button>
          
        )}
        {isLoggedIn && eligibleProposals.length > 0 &&(
          <button className='CreateProposalButton' onClick={() => setShowGlobal(!showGlobal)}>
            {showGlobal ? 'Hide Global ' : 'Show Global '}<br />
            Proposals
          </button>
        )}
      {needsKey && 
        <div className={isDarkMode ? 'modalBackground dark-mode' : 'modalBackground'}>
          <div className={isDarkMode ? 'modal dark-mode' : 'modal'}>
            <h2>Key missing</h2>
            <p>You need one of the following Keys to create a proposal:</p>
            {users.map((user, index) => <UserDiv key={index} user={user} setError={setError} />)}
            {errorBuy && 
              <div className='ErrorDiv' style={{border: '1px solid rgba(255, 0, 0, 0.2)', borderRadius: '10px', backgroundColor: 'rgba(255, 0, 0, 0.2)', maxHeight: '15%', overflow: 'scroll', marginTop: '2em'}}>
                <p>{errorBuy}</p>
              </div>
              
            }
            <button className='CloseButtonModal' onClick={() => {setNeedsKey(false); setError(null);}} >X</button>
          </div>
        </div>
      }
      {isCreating && <CreateProposalForm onSubmit={handleSubmit} isCreating={isCreating} setIsCreating={setIsCreating} />}
  </div>
  </GlobalContext.Provider>
  );
}

export default CreateProposal;