import React, { useState, useEffect } from 'react';

function Countdown({ endTime }) {
    const [countdown, setCountdown] = useState(null);
  
    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = endTime * 1000 - now;
  
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
        setCountdown(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
  
        if (distance < 0) {
          clearInterval(interval);
          setCountdown("EXPIRED");
        }
      }, 1000);
      return () => clearInterval(interval);
    }, [endTime]);
  
    return <small>Ends: {countdown}</small>;
  }
  export default Countdown;