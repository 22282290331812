import React from 'react';
import Header from './Header';
import CreateProposal from './Proposals';
import Proposals from './AllProposals';
import { useState, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { EligibleProposalsContext } from '../context/EligibleProposalsContext';
import { CreateProposalContext } from '../context/CreateProposalContext';
import { DarkModeContext } from '../context/DarkModeContext';
import Footer from './Footer';
const { REACT_APP_THIRDWEB_CLIENT_ID } = process.env;



function LandingPage() {
const [showGlobal, setShowGlobal] = useState(true);
const [eligibleProposals, setEligibleProposals] = useState([]);
const { isDarkMode } = useContext(DarkModeContext);
const [creationSuccessful, setCreationSuccessful] = useState([false]);

  return (
    <EligibleProposalsContext.Provider value={{ eligibleProposals, setEligibleProposals }}>
            <GlobalContext.Provider value={{ showGlobal, setShowGlobal }}>
              <CreateProposalContext.Provider value={{ creationSuccessful, setCreationSuccessful }}>
    <div className={`App ${isDarkMode ? "dark-mode" : ""}`}>
      <div className={`App-wrapper ${isDarkMode ? "dark-mode" : ""}`}>
        <Header />
        <CreateProposal />
        <Proposals />
       
      </div>
    </div>
    <Footer />
    </CreateProposalContext.Provider>
    </GlobalContext.Provider>
    </EligibleProposalsContext.Provider>
  );
}

export default LandingPage;
