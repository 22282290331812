import React from 'react';
import { BuySharesButton } from '../utils/web3Actions';
import ReadPriceOfShare from './ReadPriceOfShare';
import { FTaddress } from '../utils/address';


export async function fetchUser(address) {
  const cachedUserData = localStorage.getItem(address);

  if (cachedUserData) {
    const parsedData = JSON.parse(cachedUserData);
    const currentTime = Date.now();
    const timeDifference = currentTime - parsedData.timestamp;
    if (timeDifference < 30 * 60 * 1000) {
      return parsedData;
    }
  }

  let response;
  try {
    response = await fetch(`https://frenfren.pro/api/users/${address}`);
    if (!response.ok && response.status !== 404) {
      console.error('Fetch failed:', response.status, response.statusText);
      response = null;
      console.log(response);
    }
  } catch (err) {
    response = null;
    console.log('Fetch failed:', err);
  }

  let data;
  if (!response || (!response.ok && response.status === 404)) {
    data = {
      isDefault: true,
      twitterName: "Error fetching user",
      twitterPfpUrl: "https://media.printables.com/media/prints/436782/images/3612086_de8d1459-46bc-4e8c-89f1-c7bf2dda8e19/thumbs/inside/1280x960/png/download.webp",
      displayPrice: "0.000 Ξ",
      userAddress: "0x36fc2a1f53d394232116f62d9401daa81dad04e0",
      twitterlink: `https://twitter.com/heyimstoned_v`,
      portfolioValue: "0.000 Ξ",
      timestamp: Date.now(),
    };
  } else {
    data = await response.json();
    data = {
      twitterName: data.twitterName,
      twitterPfpUrl: data.twitterPfpUrl,
      displayPrice: parseFloat(data.key.value).toFixed(3) + " Ξ",
      userAddress: data.address,
      twitterlink: `https://twitter.com/${data.twitterUsername}`,
      portfolioValue: parseFloat(data.portfolio.value).toFixed(3) + " Ξ",
      timestamp: Date.now(),
    };

    if (response && response.ok) {
      localStorage.setItem(address, JSON.stringify(data));
    } else {
      data.isDefault = true;
      localStorage.setItem(address, JSON.stringify(data));
    }
  
    return data;
  }
}




export function UserDiv({ user, setError }) {

  const contractAddress = FTaddress; 
  const { data: userSharePrice } = ReadPriceOfShare(user.userAddress, 1);
  const buyString = (userSharePrice / 10**18).toString();





  return (
    <div className='KeyUsers'>
      <img className="KeyUser-image" src={user.twitterPfpUrl} alt='' />
      <p className="KeyUser-name">{user.twitterName}</p>
      <p className="KeyUser-price">{parseFloat(buyString).toFixed(4) + " ETH"}</p>
      <BuySharesButton contractAddress={contractAddress} shareSubject={user.userAddress} PriceString={buyString} setError={setError} />

    </div>
  );
}

export const KeyUsers = [
  "0x4b61add9ef41bb2e804290316fe14a06cf2d4ffe", //VWF
  "0x36fc2a1f53d394232116f62d9401daa81dad04e0", //stoned
  "0xb180b7eec9fb71388ccf99eb6922c9d120c635c1", //data
  "0xf36e13137726dfe12183cb56253528455a8343cc",  //drip
];

export async function fetchKeyUsers( setError ) {
  const users = await Promise.all(KeyUsers.map(fetchUser));
  return users;
}





