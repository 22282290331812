import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import MultipleProposals from './MultipleProposals';
import { BeatLoader } from "react-spinners";
import { fetchUser } from '../utils/KeyUsers';
import { useAddress } from "@thirdweb-dev/react";
import { useIsKeyHolder } from '../utils/sc_interactions';
import placeholderimg from '../utils/VoteWithFrens_Placeholde.png';
import { useGetVoterEligibleProposals, useGetAllData } from '../utils/sc_interactions';
import { DarkModeContext } from '../context/DarkModeContext';
import Placeholderimg from '../utils/VoteWithFrens_Placeholde.png';



function AuthorProposals() {
  const userAddress = useAddress();
  const {author} = useParams();
  const [ userEligiblesActive, isLoading ] = useGetVoterEligibleProposals(author)
  const [ allData, isAllDataLoading ] = useGetAllData(author);
  const [ eligibleProposals, setEligibleProposals ] = useState(false);
  const [ authorsData, setAuthorsData] = useState(false);
  const [ activeTab, setActiveTab] = useState('active');
  const isActive = activeTab === 'active';
  const [ activeProposals, setActiveProposals] = useState([]);
  const [ inactiveProposals, setInactiveProposals] = useState([]);
  const [ isKeyholder, isKeyHolderLoading] = useIsKeyHolder(author, userAddress);
  const { isDarkMode } = useContext(DarkModeContext);
  

  useEffect(() => {

    if (userEligiblesActive && allData ) {

      const filteredProposals = userEligiblesActive.filter(proposal => proposal.author === author);
      const combinedProposals = [...filteredProposals, ...allData];
      const uniqueProposals = combinedProposals.reduce((acc, current) => {
        const x = acc.find(item => item.topic === current.topic && item.details === current.details);
        if (!x) {
          const x = acc.find(item => item.topic === current.topic && item.details === current.details);
          if (!x) {
            if (isKeyholder) {
              return acc.concat([current]);
            } else {
              if (current.global === true) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }
          } else {
            return acc;
          }
        } else {
          

          return acc;
        }
      }, []);
      

      setEligibleProposals(uniqueProposals);

    }
  }, [userEligiblesActive, authorsData, userAddress]);


  useEffect(() => {
    if (activeProposals.length === 0) {
      setActiveTab('inactive');
    }
    else {
      setActiveTab('active');
    }
  }, [activeProposals, inactiveProposals]);
  


  useEffect(() => {
    if (eligibleProposals) {
        const currentTimes = Date.now();
        const currentTime = Math.floor(currentTimes / 1000);
        const activeProposals = eligibleProposals.filter(proposal => 
            proposal.endTime > currentTime 

        );
        const inactiveProposals = eligibleProposals.filter(proposal => 
            proposal.endTime <= currentTime
        );
        setActiveProposals(activeProposals);
        setInactiveProposals(inactiveProposals);
    }
}, [eligibleProposals, author, isKeyholder]);
            

    useEffect(() => {
        if (!authorsData && author) {
        const fetchAuthorsData = async () => {
          const data = await fetchUser(author);
          setAuthorsData(data);
        };
      
        fetchAuthorsData();
    }
      }, []);


  if (!userAddress) {
    return (
      <div className={isDarkMode ? "App dark-mode" : "App"}>
      <div className={isDarkMode ? "App-wrapper dark-mode" : "App-wrapper"}>
        <Header />
        <div className='no-proposal-container' 
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '87vh', width: 'auto'}}>    
      <img src={placeholderimg} alt="VoteWithFrens Placeholder" style={{width: 'auto', height: '50%', alignself: 'center'}} />  
      <p>Please log in to interact with this dApp</p>
    </div>
      
      </div>
    </div>
    )
  }


  



  if (isLoading || !eligibleProposals || isKeyHolderLoading || isAllDataLoading || !authorsData) {
    return(

        <div className={isDarkMode ? "App dark-mode" : "App"}>
          <div className={isDarkMode ? "App-wrapper dark-mode" : "App-wrapper"}>
            <Header />
            <Link style={{ textDecoration: 'none' }} to="/">
            <button className='CreateProposalButton' >Back to Homepage</button>
            </Link>
            <div className='fetch-beatloader'>
                <BeatLoader color="#00bafa" size={25} />
            <p>Fetching Proposal...</p>
          </div>
          </div>
        </div>

    )
  }




  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
      <Header />
      <Link style={{ textDecoration: 'none' }} to="/">
        <button className='CreateProposalButton' style={{marginBottom: '2%'}}>Back to Homepage</button>
      </Link>
        <div>
          <button
            className={`tab ${isActive ? 'active' : ''} ${activeProposals.length === 0 ? 'disabled' : ''} ${isDarkMode ? 'dark-mode' : ''}`}
            onClick={() => setActiveTab('active')}
            disabled={activeProposals.length === 0}
            title={activeProposals.length === 0 ? 'No active proposals available' : ''}
          >
            Active Proposals
          </button>
          <button
            className={`tab ${!isActive ? 'active' : ''} ${inactiveProposals.length === 0 ? 'disabled' : ''} ${isDarkMode ? 'dark-mode' : ''}`}
            onClick={() => setActiveTab('inactive')}
            disabled={inactiveProposals.length === 0}
            title={inactiveProposals.length === 0 ? 'No inactive proposals available' : ''}
          >
            Inactive Proposals
          </button>
          {activeTab === 'active' ? (
            activeProposals.length === 0 ? (
              <div className='no-proposal-container' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '87vh', width: '87vw', margin: 'auto'}}>    
                <img src={placeholderimg} alt="VoteWithFrens Placeholder" style={{width: 'auto', height: '50%', alignself: 'center'}} />  
                {isKeyholder && userAddress ? (
                  <p>There are currently no active proposals by this author</p>
                ) : (
                  <p>There are currently no Global proposals by this author</p>
                )}
              </div>
            ) : (
              activeProposals.map((proposal, index) => (
                <MultipleProposals key={index} proposal={proposal} authorsData={authorsData}/>
              ))
            )
          ) : (
            inactiveProposals.length === 0 ? (
              <div className='no-proposal-container' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '87vh', width: '87vw', margin: 'auto'}}>    
                <img src={placeholderimg} alt="VoteWithFrens Placeholder" style={{width: 'auto', height: '50%', alignself: 'center'}} />  
                {isKeyholder && userAddress ? (
                  <p>There are currently no active proposals by this author</p>
                ) : (
                  <p>There are currently no Global proposals by this author</p>
                )}
              </div>
            ) : (
              inactiveProposals.map((proposal, index) => (
                <MultipleProposals key={index} proposal={proposal} authorsData={authorsData}/>
              ))
            )
          )}
        </div>
      <Footer />
    </div>
  );
}

export default AuthorProposals;
