import { useEffect, useState } from 'react';
import { useContract, useContractRead, useAddress } from "@thirdweb-dev/react";
import { VWFaddress, FTaddress } from './address';

export function useGetVoterEligibleProposals(_voter) {

    const [proposals, setProposals] = useState([]);
    const { contract } = useContract(VWFaddress);
    const { data: activeData, isLoading: activeLoading } = useContractRead(contract, "getVoterEligibleProposals", [_voter, true]);
    const { data: inactiveData, isLoading: inactiveLoading } = useContractRead(contract, "getVoterEligibleProposals", [_voter, false]);
    const userAddress = useAddress();


    useEffect(() => {
        if (!activeLoading && activeData && !inactiveLoading && inactiveData) {
            try {
                const mapData = (data) => {
                    let result = [];
                    data.forEach((outerArray) => {
                        outerArray.forEach((proposalArray, index) => {
                            result.push({
                                topic: proposalArray.title,
                                details: proposalArray.description,
                                author: proposalArray.proposer,
                                options: proposalArray.voteOptions,
                                votes: proposalArray.voteCounts.map(vote => parseInt(vote, 16)),
                                endTime: proposalArray.deadline.toNumber(),
                                closed: proposalArray.closed,
                                id: index,
                                voted: proposalArray.voters.includes(userAddress),
                                global: proposalArray.global,
                                owner: proposalArray.owner,
                                voters: proposalArray.voters,
                                internalID: proposalArray.proposalId.toNumber(),

                            });
                        });
                    });
                    return result;
                };
    
                const activeProposals = mapData(activeData);
                const inactiveProposals = mapData(inactiveData);
                const newProposals = [...activeProposals, ...inactiveProposals];
    
                setProposals(newProposals);
            } catch (err) {
                console.log(err);
            }
        }
    }, [activeLoading, activeData, inactiveLoading, inactiveData]);

    return  [proposals, activeLoading || inactiveLoading] ;
}



export function useIsWlKeyHolder(userAddress) {
    const { contract } = useContract(VWFaddress);
    const { data, isLoading } = useContractRead(contract, "isWlKeyHolder", [userAddress])
    return [data];
}

export function useGetProposalByIndex(_address, _index, isActive) {
    const { contract } = useContract(VWFaddress);
    const { data, isLoading, error } = useContractRead(contract, "getProposalByIndex", [_address, _index, isActive]);
    return [ data, isLoading, error ];
}

export function useGetMyProposals(_address, isActive) {
    const { contract } = useContract(VWFaddress);
    const { data, isLoading } = useContractRead(contract, "getMyProposals", [_address, isActive]);
    return [ data, isLoading ];
}

export function useIsKeyHolder(userAddress, proposerAddress) {
    const { contract } = useContract(FTaddress);
    const { data, isLoading } = useContractRead(contract, "sharesBalance", [userAddress, proposerAddress])
    const dataInt = parseInt(data, 16);
    const isBiggerThanZero = dataInt > 0;
    
    return [isBiggerThanZero, isLoading];
}

export function useGetAllData(user) {   
    const { contract } = useContract(VWFaddress);
    const { data, isLoading } = useContractRead(contract, "getAllDataByAddress", [user, true])
    const { data: data2, isLoading: isLoading2 } = useContractRead(contract, "getAllDataByAddress", [user, false])
    const userAddress = useAddress();

    if (isLoading || isLoading2) {
        return [[], true];
    }


    const combinedData = Array.isArray(data) && Array.isArray(data2) ? [...data, ...data2] : [];

    
    const combinedDataObject = combinedData.map((item, index) => {
        return {
            topic: item.title,
            details: item.description,
            author: item.proposer,
            options: item.voteOptions,
            votes: item.voteCounts.map(vote => parseInt(vote, 16)),
            endTime: item.deadline.toNumber(),
            closed: item.closed,
            id: index,
            voted: item.voters.includes(userAddress),
            global: item.global,
            owner: item.owner,
            voters: item.voters,
            internalID: item.proposalId.toNumber(),
        };
    });
    return [combinedDataObject, false];
}

export const useLastProposalID = () => {
    const { contract } = useContract(VWFaddress);
    const { data, isLoading } = useContractRead(contract, "proposalId");
    console.log("aaaa1", data);
    return [data, isLoading];
}
