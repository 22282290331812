import React from 'react';
import { useContext } from 'react';
import { ConnectWallet } from "@thirdweb-dev/react";
import BetaWarning from './BetaWarning';
import { DarkModeContext } from '../context/DarkModeContext';

export default function Header() {

  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div>
    <BetaWarning />
    <header className="header-container">
      <h1 className="App-title">
        <span style={{color: '#00bafa'}}>Vote</span>
        <span style={{color: isDarkMode ? 'grey' : ''}} >With</span>
        <span style={{color: '#00bafa'}}>Frens</span>
      </h1>
      <div className="button-container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        
        <ConnectWallet
        theme={isDarkMode ? "dark" : "light"}
        switchToActiveChain={true}
        className='Login-button'
        />
      </div>
    </header>
    </div>
  );
}
