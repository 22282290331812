import React, { useState, useEffect, useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faGlobe, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import { fetchUser } from '../utils/KeyUsers';
import { useGetVoterEligibleProposals } from '../utils/sc_interactions';
import { useAddress } from "@thirdweb-dev/react";
import { BeatLoader } from "react-spinners";
import { CloseVoteButton, VoteButton } from '../utils/web3Actions.js';
import { GlobalContext } from '../context/GlobalContext';
import placeholderimg from '../utils/VoteWithFrens_Placeholde.png';
import { EligibleProposalsContext } from '../context/EligibleProposalsContext';
import { Link } from 'react-router-dom';
import { FaCopy, FaTwitter, FaExternalLinkAlt } from 'react-icons/fa';
import VotersModal from './VotersModal';
import portatrait_placeholder from '../utils/Portrait_Placeholder.png';
import Countdown from './Countdown';
import { DarkModeContext } from '../context/DarkModeContext';



function Proposal({ proposal, isActive, authorsData }) {
  const [isOpen, setIsOpen] = useState(false);
  const votes = proposal.votes;
  const highestVoteCount = Math.max(...votes);
  const [chartHeight, setChartHeight] = useState(0);
  const userAddress = useAddress();
  const [isCopied, setIsCopied] = useState(false);
  const [ votersData, setVotersData ] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const authorData = authorsData[proposal.author];
  const { isDarkMode } = useContext(DarkModeContext);
  const data = proposal.options.map((option, index) => ({
    name: option,
    value: votes[index]
  }));
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  



  useEffect(() => {
    const optionsContainer = document.querySelector('.voting-options');
    if (optionsContainer) {
      setChartHeight(optionsContainer.offsetHeight - 50);
    }
  }, [isOpen]);



  useEffect(() => {
    setIsOpen(false);
  }, [isActive]);



  

  useEffect(() => {
    const fetchVotersData = async () => {
    const votersDataPromises = proposal.voters.map(fetchUser);
    const votersDataArray = await Promise.all(votersDataPromises);
      setVotersData(votersDataArray);
    };
  
    fetchVotersData();
  }, [proposal.voters]);



  return (
    <div className={`App-proposal ${isDarkMode ? 'dark-mode' : ''}`} onClick={() => {

      if (window.innerWidth < 970 && authorData) {
        setIsOpen(!isOpen);
      }
    }}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Link 
          to={`/${proposal.owner}/${proposal.id}/${proposal.closed}`} 
          style={{fontSize: '0.8em', color: 'black', textDecoration: 'none'}}
          onMouseOver={(e) => {e.currentTarget.style.textDecoration = 'underline'}}
          onMouseOut={(e) => {e.currentTarget.style.textDecoration = 'none'}}
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(`${window.location.origin}/${proposal.owner}/${proposal.internalID}/${proposal.closed}`);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000); 
          }}
        >
          {isDarkMode ? <span style={{color: 'white'}}>Copy Proposal Link</span> : 'Copy Proposal Link'}
        </Link>
        <button 
          style={{ border: 'none', background: 'none', cursor: 'pointer'}}
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(`${window.location.origin}/${proposal.owner}/${proposal.internalID}/${proposal.closed}`);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000); 
          }}
        >
          {isCopied ? 'Copied!' : <FaCopy style={{ color: '#00bafa' }} />}
        </button>
      </div>
      <div className='proposal-content'>
        <div className='author-info'>
        {window.innerWidth > 970 && authorData && (
                        <>
                            <div>
                                <p className='author-names'>
                                    Author:{" "}
                                    <a href={`/${proposal.author}`} title="Forward to user proposals">{authorData.twitterName} <FaExternalLinkAlt size={10}/></a>
                                </p>
                                <p><small style={{fontSize: '0.65em', marginTop: '-2em'}}>*Click to see all proposals by this user</small></p>
                            </div>
                            <a href={authorData.twitterlink}>
                                <img  className='author-image' 
                                    src={authorData.twitterPfpUrl} 
                                    alt={authorData.twitterName} 
                                    onError={(e)=>{e.target.onerror = null; e.target.src=portatrait_placeholder}}
                                />
                            </a>
                        </>
                    )}
        </div>
        <h1 style={{fontSize: window.innerWidth < 970 ? '1.3em' : '1.8em'}}>{proposal.topic}</h1>
        {window.innerWidth < 970 && authorData && (
                    <div className='mobile-author-container'>
                        <p className='mobile-author'>
                            Author:{" "} 
                            <a href={`/${proposal.author}`} title="Forward to user proposals">{authorData.twitterName} <FaExternalLinkAlt size={10}/></a>
                        </p>
                        <a href={authorData.twitterlink}>
                            <img style={{ marginLeft: '10px'}} className='mobile-image' src={authorData.twitterPfpUrl} alt={authorData.twitterName} />
                        </a>
                    </div>
                )}
                {window.innerWidth < 970 && (
                    <div><small style={{fontSize: '0.65em', marginTop: '-2em'}}>*Click Name to see all proposals by this user</small></div>
                )}
        {isOpen ? (
          <button className="expand-button" onClick={(e) => {setIsOpen(!isOpen);}}>
             Show Less
          </button>
        ) : ((isActive && proposal.endTime * 1000 <= new Date().getTime() && proposal.author === userAddress) || proposal.ended) ? (
          <CloseVoteButton proposalId={proposal.id} proposer={proposal.owner} setIsOpen={setIsOpen} />
        ) : (
          <button className="expand-button" onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon icon={faSearch} /> View Proposal
          </button>
        )}
        <br />
        <div className="Countdown">
          {proposal.global && (
            <label style={{
              backgroundColor: isDarkMode ? 'grey' : 'lightblue', 
              borderRadius: '10px', 
              padding: '4px', 
              fontSize: '0.7em', 
              marginRight: '10px'
            }}>
              <FontAwesomeIcon icon={faGlobe} /> Global
            </label>
          )}
          <Countdown endTime={proposal.endTime} />
        </div>
        {isOpen && (
          <div className='voting-and-chart'>
            <div className='voting-details'>
              <p><b>Details: </b></p>
              <p>{proposal.details}</p>
              {proposal.voted && (
                <small>*You have already voted on this proposal</small>
              )}
            </div>
            <div className='options-and-chart'>
              <div className='voting-options'>
                {proposal.options.map((option, index) => (
                  <div key={index} className="voting-option-frame" onClick={(e) => e.stopPropagation()}>
                    <p style={!isActive && votes[index] === highestVoteCount ? { fontWeight: 'bold' } : {}}>{option}</p>
                    {isActive && !proposal.voted ? (
                      <VoteButton proposer={proposal.owner} proposalIndex={proposal.id} voteIndex={index} />
                    ) : isActive || !isActive ? (
                      <small>{votes[index]} Votes</small>
                    ) : null}
                  </div>
                ))}
              </div>
              <p style={{ marginTop: '1%', marginLeft: "1%" }}>{isActive ? 'Current Results:' : 'Final Results:'}</p>
              <div className='BarChart' style={{ height: chartHeight }}>
                <div className="chart-wrapper">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={data} margin={{ top: 5, right: 30, left: -30, bottom: 0 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div style={{marginTop: window.innerWidth <= 970 ? '10%' : '2%'}}>
              {proposal.voters.length === 0 ? (
                <p><b>No voters on this proposal yet</b></p>
              ) : (
                <>
              <b>Voted on by:</b>
              <p style={{ display: 'flex', flexWrap: 'wrap' }}>
                {votersData.slice(0, 3).map((voterData, index) => (
                  <small key={index} style={{ marginRight: '5px' }}>
                    <a href={voterData.twitterlink} target="_blank" rel="noopener noreferrer" onError="https://twitter.com/heyimstoned_v" style={{ color: '#00bafa' }}>
                      {voterData.twitterName} <FaTwitter />
                    </a>
                    {index < votersData.slice(0, 3).length - 1 ? ',' : ''}
                  </small>
                ))}
                {votersData.length > 3 && (
                  <a 
                    href="#" 
                    style={{fontSize: 'small', color: '#00bafa'}}
                    onClick={(e) => {
                      e.preventDefault(); 
                      toggleModal(); 
                    }}
                  >
            and {votersData.length - 3} more
          </a>
        )}
      </p>
    </>
  )}
</div>
          </div>
          
        )}
      </div>
      {isModalOpen && 
  <VotersModal 
    votersData={votersData} 
    toggleModal={toggleModal} 
  />
}
    </div>
  );
}

function ActiveProposals() {
  const [activeTab, setActiveTab] = useState('active');
  const isActive = activeTab === 'active';
  const [authorsData, setAuthorsData] = useState({});
  const {eligibleProposals, setEligibleProposals} = useContext(EligibleProposalsContext);
  const [activeProposals, setActiveProposals] = useState([]);
  const [inactiveProposals, setInactiveProposals] = useState([]);
  const address = useAddress();
  const [ActiveProposals, isLoading] = useGetVoterEligibleProposals(address);
  const { showGlobal, setShowGlobal } = useContext(GlobalContext);
  const { isDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    if (address && ActiveProposals) {
      setEligibleProposals(ActiveProposals);
    }
  }, [address, ActiveProposals]);


  
  useEffect(() => {
    if (eligibleProposals) {
      const now = new Date().getTime();
      const activeProposals = eligibleProposals.filter(proposal =>
        (proposal.endTime * 1000 > now && !proposal.closed) ||
        (proposal.author === address && !proposal.closed)
      );
  
      activeProposals.sort((a, b) => {
        const aIsAuthorAndNotEnded = a.author === address && !a.closed && a.endTime * 1000 <= new Date().getTime();
        const bIsAuthorAndNotEnded = b.author === address && !b.closed && b.endTime * 1000 <= new Date().getTime();
  
        if (aIsAuthorAndNotEnded && !bIsAuthorAndNotEnded) {
          return -1;
        } else if (!aIsAuthorAndNotEnded && bIsAuthorAndNotEnded) {
          return 1;
        } else {
          return (a.endTime * 1000 - new Date().getTime()) - (b.endTime * 1000 - new Date().getTime());
        }
      });
  
      const inactiveProposals = eligibleProposals.filter(proposal =>
        (proposal.endTime * 1000 <= now && proposal.author !== address) ||
        proposal.closed
      );
  
      setActiveProposals(activeProposals);
      setInactiveProposals(inactiveProposals);
  
      if (activeProposals.length === 0 && inactiveProposals.length > 0) {
        setActiveTab('inactive');
      } else {
        setActiveTab('active');
      }
    }
    }, [eligibleProposals, address]);



  useEffect(() => {
    const fetchAllAuthors = async () => {
      if (eligibleProposals.length === 0) {
        return;
      }

      const allProposals = eligibleProposals;
      const uniqueAuthors = [...new Set(allProposals.map(proposal => proposal.author))];
      const authorsDataPromises = uniqueAuthors.map(author => fetchUser(author));
      const authorsDataArray = await Promise.all(authorsDataPromises);
      const authorsDataObject = authorsDataArray.reduce((acc, curr, index) => {
        acc[uniqueAuthors[index]] = curr;

        return acc;
      }, {});
      setAuthorsData(authorsDataObject);
    };
    fetchAllAuthors();
  }, [ActiveProposals, eligibleProposals]);





  

  return (
    <GlobalContext.Provider value={{ showGlobal, setShowGlobal }}> 
    <div className={`App-proposal ${isDarkMode ? 'dark-mode' : ''}`} style={{border: 'none', boxShadow: 'none'}}>

      {address ? (
        isLoading ? (
          <div className='fetch-beatloader'>
            <BeatLoader color="#00bafa" loading={isLoading} size={25} />
            <p>Fetching Proposals...</p>
          </div>
        ) : (
          <>
            <button
              className={`tab ${isActive ? 'active' : ''} ${activeProposals.length === 0 ? 'disabled' : ''} ${isDarkMode ? 'dark-mode' : ''}`}
              onClick={() => setActiveTab('active')}
              disabled={activeProposals.length === 0}
              title={activeProposals.length === 0 ? 'No active proposals available' : ''}
            >
              Active Proposals
            </button>
            <button className={`tab ${!isActive ? 'active' : ''} ${inactiveProposals.length === 0 ? 'disabled' : ''} ${isDarkMode ? 'dark-mode' : ''}`}
              onClick={() => setActiveTab('inactive')}
              disabled={inactiveProposals.length === 0}
              title={inactiveProposals.length === 0 ? 'No inactive proposals available' : ''}
            >
              Inactive Proposals
            </button>

            <div className={`proposals-grid ${isDarkMode ? 'dark-mode' : ''}`}>
              {activeTab === 'active' ? (
                activeProposals.length === 0 ? (
                  <div className='no-proposal-container' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '87vh', width: '87vw'}}>    
                    <img src={placeholderimg} alt="VoteWithFrens Placeholder" style={{width: 'auto', height: '50%', alignself: 'center'}} />  
                    <p>There are currently no active proposals by any Keys you own</p>
                  </div>
                ) : (
                  activeProposals
                  .filter(proposal => showGlobal || !proposal.global)
                  .map((proposal, index) => (
                    <Proposal key={index} proposal={proposal} isActive={true} authorsData={authorsData} />
                  ))
                )
              ) : (
                inactiveProposals.length === 0 ? (
                  <div className='no-proposal-container' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '87vh', width: '87vw'}}>    
                    <img src={placeholderimg} alt="VoteWithFrens Placeholder" style={{width: 'auto', height: '50%', alignself: 'center'}} />  
                    <p>There are currently no inactive proposals by any Keys you own</p>
                  </div>
                ) : (
                  inactiveProposals
                  .filter(proposal => showGlobal || !proposal.global)
                  .map((proposal, index) => (
                    <Proposal key={index} proposal={proposal} isActive={false} authorsData={authorsData} />
                  ))
                )
              )}
            </div>
          </>
        )
      ) : (

        <div className='no-proposal-container' 
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '87vh', width: 'auto'}}>    
          <img src={placeholderimg} alt="VoteWithFrens Placeholder" style={{width: 'auto', height: '50%', alignself: 'center'}} />  
          <p>Please log in to interact with this dApp</p>
        </div>
      )}
    </div>
    </GlobalContext.Provider>
  );
}

export default ActiveProposals;
