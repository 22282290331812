import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { DarkModeContext } from '../context/DarkModeContext';
import { useContext } from 'react';


const BetaWarning = () => {
  const isDarkMode = useContext(DarkModeContext);


  return (
    <div className={`warningDiv`}>
      <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '1em' }} />
      <p style={{ fontSize: '1em', margin: '0.2em' }}>Caution:  VoteWithFrens Open Beta - Proposals May Be Reset with Prior Notice</p>
      <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: '1em' }} />
    </div>
  );
};

export default BetaWarning;
