import React, { useState, useEffect, useContext } from 'react';
import { CreateProposalButton } from '../utils/web3Actions';
import { VWFaddress } from '../utils/address';
import { CreateProposalContext } from '../context/CreateProposalContext';
import { DarkModeContext } from '../context/DarkModeContext';	




function CreateProposalForm({ setIsCreating }) {
  const [topic, setTopic] = useState('');
  const [options, setOptions] = useState(['', '']);
  const [endTime, setEndTime] = useState('');
  const [newOption, setNewOption] = useState(''); 
  const [isWeighted, setIsWeighted] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [step, setStep] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const [details, setDetails] = useState('');
  const [charCount2, setCharCount2] = useState(0);
  const [error, setError] = useState(null);
  const {creationSuccessful, setCreationSuccessful} = useContext(CreateProposalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isDarkMode } = useContext(DarkModeContext);


  const handleNext = () => {
    if (step === 0 && (!topic || !details)) {
      alert('Please fill in all fields');
      return;
    }
    if (step === 1 && options.includes('')) {
      alert('Please fill in all options');
      return;
    }
    if (step === 2 && !endTime ) {

      alert('Please select an ending time');
      return;
    }
    if (step === 2 && endTime) {
      const currentDate = new Date();
      const selectedDate = new Date(endTime);
      const timeDifference = Math.abs(selectedDate.getTime() - currentDate.getTime());
      const differenceInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
      if (differenceInDays > 30) {
      alert('Please select an ending time not further out then 30 Days');
      return;
    }
    }
    setStep(step + 1);
  };



  const handleBack = () => {
    setStep(step - 1);
  };

  const jumpToStep = (stepNumber) => {
    setStep(stepNumber);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const addOption = () => {
    if (options.length < 5) {
      setOptions([...options, newOption]);
      setNewOption(''); 
    } else {
      alert('Maximum of 5 options reached');
    }
  };

  const removeOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };


  const handleTopicChange = (e) => {
    if (e.target.value.length <= 50) {
      setTopic(e.target.value);
      setCharCount(e.target.value.length);
    }
  };

  const handleDetailsChange = (e) => {
    if (e.target.value.length <= 128) {
      setDetails(e.target.value);
      setCharCount2(e.target.value.length);
      
    }
  };

  useEffect(() => {
    setIsSubmitting(true);
  }, []);

  
  useEffect(() => {
    if (creationSuccessful === true) {
      setIsCreating(false);
    }
  }
  , [creationSuccessful]);

  useEffect(() => {
    setCreationSuccessful(false);
  }, []);

  
  


  return (
    <div className={isDarkMode ? "overlay-bg dark-mode" : "overlay-bg"}>
      <div className={isDarkMode ? "overlay dark-mode" : "overlay"}>
        <div className="overlay-container">
          <button className="closing-button" style={{position: 'absolute', top: '0', right: '1rem'}} onClick={() => setIsCreating(false)}>X</button>
            {step === 0 && (
            <>
            <div className="overlay-header">
                <h1>
                <small>Step {step + 1} of 6</small>
                <p style={{color: '#00bafa', fontSize: '2rem'}}>Create your proposals Topic</p>
                </h1>
            </div>
              <textarea value={topic} onChange={handleTopicChange} placeholder='Topic' required />
              <p style={{textAlign: 'right', marginRight: '10%' }}>{charCount}/50</p>
              <textarea value={details} onChange={handleDetailsChange} placeholder='Details' required />
              <p style={{textAlign: 'right', marginRight: '10%' }}>{charCount2}/128</p>
            </>
            )}
            
        </div>
      {step === 1 && (
        <div className="overlay-container">
          <div className="overlay-header">
              <h1>
              <small>Step {step + 1} of 6</small>
              <p style={{color: '#00bafa', fontSize: '2rem'}}>Add your Voting Options</p>
              </h1>
          </div>
          <div className="options-container">
            {options.map((option, index) => (
              <div key={index}>
                <input type='text' value={option} onChange={(e) => handleOptionChange(index, e.target.value)} placeholder={`Option ${index + 1}`} required />
                {options.length > 2 && <button id="removeOptionButton" onClick={() => removeOption(index)}>Remove Option</button>} 
              </div>
            ))}
            {options.length < 5 && <button id="addOptionButton" onClick={addOption}>Add Option</button>}
          </div>
        </div>
    )}
      {step === 2 && (
        <div className="overlay-container">
          <div className="overlay-header">
              <h1>
              <small className='steps'>Step {step + 1} of 6</small>
              <p style={{color: '#00bafa', fontSize: '2rem'}}>Choose an ending time</p>
              </h1>            
          </div>
            <input type='datetime-local' value={endTime} onChange={(e) => setEndTime(e.target.value)} required />
        </div>
      )}
            

      {step === 3 && (
        <div className="overlay-container">
          <div className="overlay-header">
            <h1>
              <small className='steps'>Step {step + 1} of 6</small>
                <p style={{color: '#00bafa', fontSize: '2rem'}}>Decide if the Votes are weighted</p>
              <div className="details-container">
                <small>*Weighted means that the amount of shares you own will be the amount of votes you have</small>
                <br />
                <small> Unweighted means that every address has one vote</small>
              </div>
            </h1>
          </div>
            <div className="checkbox-container">
              <input className="checkbox1" type='checkbox' checked={isWeighted} onChange={(e) => setIsWeighted(e.target.checked)} style={{transform: "scale(2.5)", margin:"2em"}} />
                <span style={{color: 'blue'}}>True</span>
              <input type='checkbox' checked={!isWeighted} onChange={(e) => setIsWeighted(!e.target.checked)} style={{transform: "scale(2.5)", margin:"2em"}} />
                <span style={{color: 'blue'}}>False</span>
            </div>
        </div>
      )}
      {step === 4 && (
        <div className="overlay-container">
          <div className="overlay-header">
            <h1>
              <small className='steps'>Step {step + 1} of 6</small>
                <p style={{color: '#00bafa', fontSize: '2rem'}}>Decide if the Proposal is Global</p>
              <div className="details-container">
                <small>*Global means voters don't need to have your key to vote on this proposal, making it open to the public</small>
                <br />
              </div>
            </h1>
          </div>
            <div className="checkbox-container">
              <input className="checkbox1" type='checkbox' checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} style={{transform: "scale(2.5)", margin:"2em"}} />
                <span style={{color: 'blue'}}>True</span>
              <input type='checkbox' checked={!isPublic} onChange={(e) => setIsPublic(!e.target.checked)} style={{transform: "scale(2.5)", margin:"2em"}} />
                <span style={{color: 'blue'}}>False</span>
            </div>
        </div>
      )}
      {step === 5 && (
  <div className="overlay-container">
    <div className="overlay-header">
      <h1>
        <small>Step {step + 1} of 6</small>
        <p style={{color: '#00bafa', fontSize: '2rem'}}>Review your Proposal</p>
      </h1>     
    </div>
    <div className={isDarkMode ? "proposal-summary dark-mode" : "proposal-summary"}>
      <p><strong>Topic:</strong> {topic} <button style={{ marginLeft: '2%', scale: '0.8'}} onClick={() => jumpToStep(0)}>Edit</button></p>
      <p><strong>Details:</strong> {details} <button style={{ marginLeft: '2%', scale: '0.8'}} onClick={() => jumpToStep(0)}>Edit</button></p>
      <p><strong>Options:</strong> {options.join(', ')} <button style={{ marginLeft: '2%', scale: '0.8'}} onClick={() => jumpToStep(1)}>Edit</button></p>
      <p><strong>End Time:</strong> {endTime} <button style={{ marginLeft: '2%', scale: '0.8'}} onClick={() => jumpToStep(2)}>Edit</button></p>
      <p><strong>Is Weighted:</strong> {isWeighted ? 'Yes' : 'No'} <button style={{ marginLeft: '2%', scale: '0.8'}} onClick={() => jumpToStep(3)}>Edit</button></p>
      <p><strong>Is Public:</strong> {isPublic ? 'Yes' : 'No'} <button style={{ marginLeft: '2%', scale: '0.8'}} onClick={() => jumpToStep(4)}>Edit</button></p>
    </div>
  </div>
)}
      {step === 5 && <CreateProposalButton 
                contractAddress={VWFaddress} 
                title={topic} 
                description={details} 
                options={options} 
                deadline={new Date(endTime).getTime()} 
                weighted={isWeighted} 
                isPublic={isPublic}
                setError={setError}
              />}
      <div style={{ display: 'flex', justifyContent: 'left', marginLeft: '9%'}}>
      {step > 0 && <button className="back-button" onClick={handleBack}>Back</button>}
    {step < 5 && <button className='back-button' onClick={handleNext}>Next</button>}
    

</div>
    </div>
    </div>
  );
}

export default CreateProposalForm;