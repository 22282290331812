import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { fetchUser } from '../utils/KeyUsers';
import SingleProposal from './SingleProposal';
import { BeatLoader } from "react-spinners";
import '../App.css';
import { DarkModeContext } from '../context/DarkModeContext';
import { useGetVoterEligibleProposals } from '../utils/sc_interactions';
import placeholderimg from '../utils/VoteWithFrens_Placeholde.png';
import { useAddress } from "@thirdweb-dev/react";
const { REACT_APP_THIRDWEB_CLIENT_ID } = process.env;






function ProposalDetail() {
  const { author, id, isClosed } = useParams();
  const userAddress = useAddress();
  let active
  if (isClosed === 'true') {
    active = false

  }
  else {
    active = true
  }
  const internalID = parseInt(id);
  const [ proposalDataRaw, isLoading ] = useGetVoterEligibleProposals(author);
  const [ eligibleProposals, setEligibleProposals ] = useState(false); 
  const [authorsData, setAuthorsData] = useState(false);
  const createdProposal = JSON.parse(sessionStorage.getItem('createdProposal'));
  const { isDarkMode } = useContext(DarkModeContext);
  


  useEffect(() => {
    if (proposalDataRaw && !createdProposal) {
      const filteredProposals = proposalDataRaw.filter(proposal => proposal.internalID === internalID);
      setEligibleProposals(filteredProposals[0]);
    }
  }, [proposalDataRaw]);

  useEffect(() => {
    if (proposalDataRaw && createdProposal) {
      const filteredProposals = proposalDataRaw.filter(proposal => proposal.internalID === internalID);
      setEligibleProposals(filteredProposals[0]);
    }
  }, []);


  useEffect(() => {
    if (eligibleProposals) {
      
      fetchUser(eligibleProposals.author)
        .then(data => setAuthorsData(data))
        .catch(error => console.error(error));
    } else if (createdProposal) {
      fetchUser(createdProposal.author)
        .then(data => setAuthorsData(data))
        .catch(error => console.error(error));
    }
  }, [eligibleProposals]);





  useEffect(() => {
    if (createdProposal) {
      setEligibleProposals(createdProposal);
    }
  }, []);



  if (!userAddress) {
    return (
      <div className={isDarkMode ? "App dark-mode" : "App"}>
      <div className={isDarkMode ? "App-wrapper dark-mode" : "App-wrapper"}>
        <Header />
        <div className='no-proposal-container' 
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '87vh', width: 'auto'}}>    
      <img src={placeholderimg} alt="VoteWithFrens Placeholder" style={{width: 'auto', height: '50%', alignself: 'center'}} />  
      <p>Please log in to interact with this dApp</p>
    </div>    
      </div>
    </div>
    )
  }

  if (!authorsData || !eligibleProposals) {
    return(
        <div className={isDarkMode ? "App dark-mode" : "App"}>
          <div className={isDarkMode ? "App-wrapper dark-mode" : "App-wrapper"}>
            <Header />
            <Link style={{ textDecoration: 'none' }} to="/">
            <button className='CreateProposalButton' >Back to Homepage</button>
            </Link>
            <div className='fetch-beatloader'>
            <BeatLoader color="#00bafa" loading={isLoading} size={25} />
            {!isLoading && (
                <div className='no-proposal-container' 
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '87vh', width: 'auto'}}>    
                    <img src={placeholderimg} alt="VoteWithFrens Placeholder" style={{width: 'auto', height: '50%', alignself: 'center'}} />  
                    <p>You are not eligible to view this Proposal</p>
                </div>
            )}
        </div>
          </div>
        </div>
    )
  }


  return (
        <div className={isDarkMode ? "App dark-mode" : "App"}>
        <div className={isDarkMode ? "App-wrapper dark-mode" : "App-wrapper"}>
        <Header />
            <div className={isDarkMode ? 'App-proposal dark-mode' : 'App-proposal'} style={{ minHeight: '80vh' }}>
            <Link style={{ textDecoration: 'none' }} to="/">
            <button className='CreateProposalButton' >Back to Homepage</button>
            </Link>
            <SingleProposal proposal={eligibleProposals} authorsData={authorsData} />
            </div>
          </div>
          <Footer />
        </div>
  );
}

export default ProposalDetail;
