import React from 'react';
import { useState } from 'react';
import './App.css';
import LandingPage from './components/LandingPage'; // import your landing page component
import { DarkModeContext } from './context/DarkModeContext';
import {
    ThirdwebProvider,
    metamaskWallet,
    coinbaseWallet,
    rainbowWallet,
  } from "@thirdweb-dev/react";
import { EligibleProposalsContext } from './context/EligibleProposalsContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProposalDetail from './components/SpecificProposal';
import AuthorProposals from './components/AuthorProposals';
import {BaseGoerli} from "@thirdweb-dev/chains";

const { REACT_APP_THIRDWEB_CLIENT_ID } = process.env;


function App() {
  const [ eligibleProposals, setEligibleProposals ] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(
    () => JSON.parse(localStorage.getItem('darkMode')) || false
  );

  function toggleDarkMode() {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      return newMode;
    });
  }

  return (
    <DarkModeContext.Provider value={{ isDarkMode, setIsDarkMode, toggleDarkMode }}>
        <EligibleProposalsContext.Provider value={{ eligibleProposals, setEligibleProposals }}>
          <ThirdwebProvider
            // activeChain={BaseGoerli}
            activeChain="base"
            clientId= {REACT_APP_THIRDWEB_CLIENT_ID}
            supportedWallets={[
              metamaskWallet(),
              coinbaseWallet(),
              rainbowWallet(),
            ]}    
          >
              <Router>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/:author" element={<AuthorProposals />} />
                  <Route path="/:author/:id/:isClosed" element={<ProposalDetail />} />
                </Routes>
              </Router>
          </ThirdwebProvider>
        </EligibleProposalsContext.Provider>
    </DarkModeContext.Provider>
  );
}

export default App;

