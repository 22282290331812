import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { CloseVoteButton, VoteButton } from '../utils/web3Actions.js';
import { FaCopy, FaTwitter, FaExternalLinkAlt } from 'react-icons/fa';
import '../App.css';
import VotersModal from './VotersModal';
import { fetchUser } from '../utils/KeyUsers';
import portrait_placeholder from '../utils/Portrait_Placeholder.png';
import Countdown from './Countdown';
import { DarkModeContext } from '../context/DarkModeContext';




function MultipleProposals({ proposal, authorsData }) {


    const [isOpen, setIsOpen] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const votes = proposal.votes
    const highestVoteCount = Math.max(...votes);
    const [chartHeight, setChartHeight] = React.useState('0px');
    const [isCopied, setIsCopied] = React.useState(false);
    const [votersData, setVotersData] = React.useState([]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { isDarkMode } = React.useContext(DarkModeContext);
    const authorData = authorsData
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
      };
    const data = proposal.options.map((option, index) => ({
        name: option,
        value: proposal.votes[index]
    }));

   
        
      useEffect(() => {
        const optionsContainer = document.querySelector('.voting-options');
        if (optionsContainer) {
            setChartHeight(optionsContainer.offsetHeight - 50);
        }
    }, [isOpen]);




    useEffect(() => {
        const fetchVotersData = async () => {
        const votersDataPromises = proposal.voters.map(fetchUser);
        const votersDataArray = await Promise.all(votersDataPromises);
          setVotersData(votersDataArray);
        };
      
        fetchVotersData();
      }, [proposal.voters]);

      


    return (
        <div className={isDarkMode ? 'App-proposal dark-mode' : 'App-proposal'} style={{maxWidth: window.innerWidth > 970 ? '60%' : '98%', alignSelf: 'center', minWidth: window.innerWidth > 970 ? '60%' : '93%', margin: '0 auto', marginTop: window.innerWidth > 970 ? '1%':'2%', marginBottom: '1%'}} onClick={() => {
            if (window.innerWidth < 970 && authorData) {
                setIsOpen(!isOpen);
            }
        }}>
        <div style={{display: 'flex', alignItems: 'center'}}>
        <Link 
          to={`/${proposal.owner}/${proposal.id}/${proposal.closed}`} 
          style={{fontSize: '0.8em', color: 'black', textDecoration: 'none'}}
          onMouseOver={(e) => {e.currentTarget.style.textDecoration = 'underline'}}
          onMouseOut={(e) => {e.currentTarget.style.textDecoration = 'none'}}
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(`${window.location.origin}/${proposal.owner}/${proposal.internalID}/${proposal.closed}`);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000); 
          }}
        >
          {isDarkMode ? <span style={{color: 'white'}}>Copy Proposal Link</span> : 'Copy Proposal Link'}
        </Link>
            <button 
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigator.clipboard.writeText(`${window.location.origin}/${proposal.owner}/${proposal.internalID}/${proposal.closed}`);
                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 1000);
                }}
                style={{ border: 'none', background: 'none', cursor: 'pointer'}}
            >
                {isCopied ? 'Copied!' : <FaCopy style={{ color: '#00bafa' }} />}
            </button>
        </div>
        <div className='proposal-header'>
            <div className='author-info'>
            {window.innerWidth > 970 && authorData && (
                        <>
                            <div>
                                <p className='author-names'>
                                    Author:{" "}
                                    <a href={`/${proposal.author}`} title="Forward to user proposals">{authorData.twitterName} <FaExternalLinkAlt size={10}/></a>
                                </p>
                                <p><small style={{fontSize: '0.65em', marginTop: '-2em'}}>*Click to see all proposals by this user</small></p>
                            </div>
                            <a href={authorData.twitterlink}>
                                <img  className='author-image' 
                                    src={authorData.twitterPfpUrl} 
                                    alt={authorData.twitterName} 
                                    onError={(e)=>{e.target.onerror = null; e.target.src=portrait_placeholder}}
                                />
                            </a>
                        </>
                    )}
            </div>
            <h1 style={{fontSize: window.innerWidth < 970 ? '1.3em' : '1.8em'}}>{proposal.topic}</h1>

            {window.innerWidth < 970 && authorData && (
                    <div className='mobile-author-container'>
                        <p className='mobile-author'>
                            Author:{" "} 
                            <a href={`/${proposal.owner}`} title="Forward to user proposals">{authorData.twitterName} <FaExternalLinkAlt size={10}/></a>
                        </p>
                        <a href={authorData.twitterlink}>
                            <img style={{ marginLeft: '10px'}} className='mobile-image' src={authorData.twitterPfpUrl} alt={authorData.twitterName} />
                        </a>
                    </div>
                )}
                {window.innerWidth < 970 && (
                    <div><small style={{fontSize: '0.65em', marginTop: '-2em'}}>*Click Name to see all proposals by this user</small></div>
                )}
            {isOpen ? (
                <button className="expand-button" onClick={(e) => {e.stopPropagation(); setIsOpen(!isOpen);}}>
                    Show Less
                </button>
            ) : ((isActive && proposal.endTime * 1000 <= new Date().getTime() ) || proposal.ended) ? (
                <CloseVoteButton proposalId={proposal.id} proposer={proposal.owner}/>
            ) : (
                <button className="expand-button" onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faSearch} /> View Proposal
                </button>
            )}
            <br />
            <div className="Countdown">
                {proposal.global && (
                    <label style={{backgroundColor: 'lightblue', borderRadius: '10px', padding: '4px', fontSize: '0.7em', marginRight: '10px'}}>
                    <FontAwesomeIcon icon={faGlobe} /> Global
                    </label>
                )}
                <Countdown endTime={proposal.endTime} />
                </div>
            {isOpen && (
                <div className='voting-and-chart'>
                    <div className='voting-details'>
                        <p><b>Details: </b></p>
                        <p>{proposal.details}</p>
                        {proposal.voted && (
                            <small>*You have already voted on this proposal</small>
                        )}
                    </div>
                    <div className='options-and-chart'>
                        <div className='voting-options'>
                            {proposal.options.map((option, index) => (
                                <div key={index} className="voting-option-frame" onClick={(e) => e.stopPropagation()}>
                                    <p style={!isActive && proposal.voted && votes[index] === highestVoteCount ? { fontWeight: 'bold' } : {}}>{option}</p>
                                     { !proposal.voted && proposal.endTime * 1000 > new Date().getTime() ? (
                                        <VoteButton proposer={proposal.owner} proposalIndex={proposal.id} voteIndex={index} />
                                    ) : proposal.voted ? (
                                        <small>{votes[index]} Votes</small>
                                    ) : <small>{votes[index]} Votes</small>}
                                </div>
                            ))}
                        </div>
                        <p style={{ marginTop: '1%', marginLeft: "1%" }}>Current Results:</p>
                        <div className='BarChart' style={{ height: chartHeight }}>
                            <div className="chart-wrapper">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={data} margin={{ top: 5, right: 30, left: -30, bottom: 0 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="value" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: window.innerWidth <= 970 ? '10%' : '2%'}}>
                        {proposal.voters.length === 0 ? (
                            <p><b>No voters on this proposal yet.</b></p>
                        ) : (
                            <>
                            <b>Voted on by:</b>
                            <p style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {votersData.slice(0, 3).map((voterData, index) => (
                                <small key={index} style={{ marginRight: '5px' }}>
                                    <a href={voterData.twitterlink} target="_blank" rel="noopener noreferrer" style={{ color: '#00bafa' }}>
                                    {voterData.twitterName} <FaTwitter />
                                    </a>
                                    {index < votersData.slice(0, 3).length - 1 ? ',' : ''}
                                </small>
                                ))}
                                {votersData.length > 3 && (
                                <a 
                                    href="#" 
                                    style={{fontSize: 'small', color: '#00bafa'}}
                                    onClick={(e) => {
                                    e.preventDefault(); 
                                    toggleModal(); 
                                    }}
                                >
                                    and {votersData.length - 3} more
                                </a>
                                )}
                            </p>
                            </>
                        )}
                        </div>
                    </div>
                )}
            </div>
            {isModalOpen && 
                <VotersModal 
                    votersData={votersData} 
                    toggleModal={toggleModal} 

                />
            }
        </div>
    );
}

export default MultipleProposals;
