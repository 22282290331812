import React from 'react';
import potrait from '../utils/Portrait_Placeholder.png';
import { FaTwitter } from 'react-icons/fa';
import placeholderimg from '../utils/VoteWithFrens_Placeholde.png';
import { DarkModeContext } from '../context/DarkModeContext';

function VotersModal({ votersData, toggleModal }) {
    const { isDarkMode } = React.useContext(DarkModeContext);
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: isDarkMode ? 'rgba(112, 109, 109, 0.8)' : 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          backgroundColor: isDarkMode ? '#131212' : 'white',
          padding: '1em',
          borderRadius: '8px',
          width: window.innerWidth <= 970 ? '90%' : '25%',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: '1em',
            }}>
            <img src={placeholderimg} alt="Placeholder" style={{ width: '10%', height: '10%' }} />
            <p style={{fontSize: '1.3em', marginLeft: '10px', color: isDarkMode ? 'white' : 'black'}}>Voters on this Proposal</p>
            <button className='closing-button' style={{ position: 'relative', marginLeft: 'auto', marginBottom: '1em', color: isDarkMode ? 'white' : 'black' }} onClick={toggleModal}>Close</button>
          </div>
          
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(35%, 1fr))',
          }}>
            {votersData.map((voterData, index) => (
              <div style={{
                display: 'flex',
                justifyContent: 'space-between', 
                marginBottom: '3%', 
              }} key={index}>
                <div style={{ flex: 0.2 }}>
                  <img src={voterData.twitterPfpUrl} alt={voterData.twitterName}
                    onError={(e) => { e.target.onerror = null; e.target.src = potrait }}
                    style={{ width: '70%', height: 'auto', borderRadius: '50%' }} />
                </div>
                <div style={{ flex: 0.8, display: 'flex', alignItems: 'center' }}> 
                  <a href={voterData.twitterlink} target="_blank" rel="noopener noreferrer" style={{ color: isDarkMode ? 'white' : '#00bafa' }}>
                    <div>
                      <p style={{ lineHeight: '1', margin: '0', color: isDarkMode ? 'white' : 'black' }}>{voterData.twitterName} <FaTwitter size={10} /></p>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
    );
  }
  export default VotersModal;