// src/utils/web3Actions.js
import { useContractWrite, useContract, Web3Button, useAddress } from "@thirdweb-dev/react";
import { utils } from 'ethers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopCircle } from '@fortawesome/free-solid-svg-icons';
import { VWFaddress } from './address';
import { CreateProposalContext } from '../context/CreateProposalContext';
import { useContext, useEffect } from 'react';
import { useGetMyProposals, useLastProposalID } from './sc_interactions';



const contractAddress = VWFaddress; 

export function BuySharesButton({ contractAddress, shareSubject, PriceString, setError }) {
  const { contract } = useContract(contractAddress);
  const { mutateAsync, isLoading, error } = useContractWrite(
    contract,
    "buyShares",
  );
  


  const handleBuyShares = async () => {
    try {
      await mutateAsync({ 
        args: [shareSubject, "1"], 
        overrides: { 
          value: utils.parseEther(PriceString) 
        } 
      });
      alert("Success: Shares purchased successfully");
    } catch (err) {
      let errorMessage = err.message;
      let index = errorMessage.indexOf('Reason:');
      if (index != -1) {
        let message = errorMessage.slice(index);
        let newlineIndex = message.indexOf('\n');
        if (newlineIndex != -1) {
          message = message.slice(0, newlineIndex);
        }
        
        console.error("Error:", message);
        setError("Error: " + message);
      }
      
      console.error(error.reason);
      
    }
  };

  return (
    <Web3Button
      className='BuyButtonModal'
      contractAddress={contractAddress}
      action={handleBuyShares}
    >
      Buy Key
    </Web3Button>
  );
}

export function CreateProposalButton({ contractAddress, title, description, options, deadline, weighted, isPublic, setError }) {
  const { creationSuccessful, setCreationSuccessful } = useContext(CreateProposalContext);
  const { contract } = useContract(contractAddress);
  const { mutateAsync, error } = useContractWrite(
    contract,
    "createProposal",
  );
  const [ lastProposalID, isLoading2 ] = useLastProposalID();


  let indexAddress
  const userAddress = useAddress();
  if (!isPublic) {
    indexAddress = userAddress;
  } else {
    indexAddress = contractAddress;
  }


  deadline = Math.floor(deadline / 1000);



  const [ data, isLoading ] = useGetMyProposals(indexAddress, true);

  
  useEffect(() => {
    if (!isLoading && creationSuccessful && !isLoading2) {
      const predictedIndex = data.length - 1;
      console.log(data);
      console.log(indexAddress)
      console.log(indexAddress)
      console.log(predictedIndex);
      const proposalObject = {
        topic: title,
        details: description,
        author: userAddress,
        options: options,
        votes: Array(options.length).fill(0),
        endTime: deadline,
        closed: false,
        id: predictedIndex,
        voted: false,
        global: isPublic,
        owner: indexAddress,
        voters: [],
        internalID: lastProposalID.toNumber(),
    }
    console.log(proposalObject);
    let proposalArray = [];
    proposalArray.push(proposalObject);
    console.log(proposalArray);
    
    sessionStorage.setItem('createdProposal', JSON.stringify(proposalObject));
    window.location.href = `${window.location.origin}/${proposalObject.owner}/${proposalObject.internalID}/${proposalObject.closed}`;
    
    }


  }, [creationSuccessful]);


  

  const handleCreateProposal = async () => {
    try {
      await mutateAsync({ 
        args: [title, description, options, deadline, weighted, isPublic], 
      });
      alert("Success: Proposal created successfully");
      setCreationSuccessful(true);
      
    } catch (err) {
      let errorMessage = err.message;
      let index = errorMessage.indexOf('Reason:');
      if (index != -1) {
        let message = errorMessage.slice(index);
        let newlineIndex = message.indexOf('\n');
        if (newlineIndex != -1) {
          message = message.slice(0, newlineIndex);
        }
        
        console.error("Error:", message);
        setError("Error: " + message);
        alert("Error: " + message);
      }
      
      console.error(error.reason);
      
    }
  };

  return (
    <Web3Button
      className='submit-button'
      contractAddress={contractAddress}
      action={handleCreateProposal}
    >
      Create Proposal
    </Web3Button>
  );
}



export function CloseVoteButton({ proposalId, proposer, setIsOpen }) {
  const { contract } = useContract(contractAddress);
  const { mutateAsync, isLoading, error } = useContractWrite(
    contract,
    "closeVote",
  );

  const handleCloseVote = async () => {
    try {
      await mutateAsync({ 
        args: [proposer, proposalId], 
      });
      alert("Voting closed successfully");
    } catch (err) {
      let errorMessage = err.message;
      let index = errorMessage.indexOf('Reason:');
      if (index != -1) {
        let message = errorMessage.slice(index);
        let newlineIndex = message.indexOf('\n');
        if (newlineIndex != -1) {
          message = message.slice(0, newlineIndex);
        }
        
        console.error("Error:", message);
        alert("Error: " + message);
      }
      
      
    }
  };

  return (
    <Web3Button
      className='close-vote-button'
      contractAddress={contractAddress}
      action={handleCloseVote}
      onError={(error) => alert("Error: " + error.message || "Unknown Error")}
      onSubmit={() => setIsOpen(true)}
    >
      <FontAwesomeIcon icon={faStopCircle} /> End Proposal
    </Web3Button>
  );
}


export function VoteButton({ proposer, proposalIndex, voteIndex }) {
  const { contract } = useContract(contractAddress);
  const { mutateAsync, isLoading, error } = useContractWrite(
    contract,
    "vote",
  );

  const handleVote = async () => {
    try {
      await mutateAsync({ 
        args: [proposer, proposalIndex, voteIndex], 
      });
      alert("Vote submitted successfully");
    } catch (err) {
      let errorMessage = err.message;
      let index = errorMessage.indexOf('Reason:');
      if (index != -1) {
        let message = errorMessage.slice(index);
        let newlineIndex = message.indexOf('\n');
        if (newlineIndex != -1) {
          message = message.slice(0, newlineIndex);
        }
        
        console.error("Error:", message);
        alert("Error: " + message);
      }
      
      
    }
  };



  return (
    <Web3Button
      className='App-option-button'
      contractAddress={contractAddress}
      action={handleVote}
    >
      Vote
    </Web3Button>
  );
}
