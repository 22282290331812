import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import {DarkModeContext} from '../context/DarkModeContext';
import Switch from "react-switch";
import { FaRegLightbulb, FaLightbulb, FaExternalLinkAlt } from 'react-icons/fa';
import { useAddress } from "@thirdweb-dev/react";
import { fetchUser } from '../utils/KeyUsers';

const Footer = () => {
  const [isUserMenuOpen, toggleUserMenu] = useState(false);
  const { isDarkMode, toggleDarkMode } = React.useContext(DarkModeContext);
  const [ user, setUser ] = useState(false);
  const userAddress = useAddress();


  useEffect(() => {
    if (userAddress) {
      fetchUser(userAddress)
        .then(data => setUser(data))
        .catch(error => console.error(error));
    }
  }
    , [userAddress]);

    return (
        <div className='footerContainer'>
            <div>
                <button onClick={() => toggleUserMenu(!isUserMenuOpen)} className= 'footerButton'>
                    <FaBars size={10} />
                </button>
                {isUserMenuOpen && (
                    <div className={isDarkMode ? 'userMenu dark-mode' : 'userMenu'}>
                        {user && (
                            <>
                                <img src={user.twitterPfpUrl} alt="User Profile" style={{ width: '50px', height: '50px', borderRadius: '50%', marginBottom: '2em' }} />
                                <small style={{ color: isDarkMode ? 'white' : 'black' }}>Logged in as:</small>
                                <p style={{ color: isDarkMode ? 'white' : 'black', marginTop: '0', fontWeight: 'bold', fontSize: '1.2em' }}>{user.twitterName}</p>
                                <div className= 'valueContainer' style={{ display: 'flex', flexDirection: 'row', marginBottom: '2em' }}>
                                    <div className= 'valueContainerItem'>
                                        <p style={{ color: isDarkMode ? 'white' : 'black', margin: '0'}}>{user.portfolioValue}</p>
                                        <small style={{ color: isDarkMode ? 'white' : 'black', fontSize: "0.6em" }}>Portfolio Value Price</small>
                                    </div>
                                    <div className= 'valueContainerItem' style={{marginLeft: '1em'}}>
                                        <p style={{ color: isDarkMode ? 'white' : 'black', margin: '0' }}>{user.displayPrice}</p>
                                        <small style={{ color: isDarkMode ? 'white' : 'black', fontSize: "0.6em" }}>Key Price</small>
                                    </div>
                                </div>
                            </>
                        )}
                        {user && (
                            <a href={`/${user.userAddress}`} className={isDarkMode ? "customLink dark-mode" : "customLink"} style={{fontWeight: 'bold', marginBottom: '1em'}}>My Proposals <FaExternalLinkAlt size={10} /></a>
                        )}
                        <div style={{marginBottom: '1em', marginTop: '1em'}}>
                            <div style={{marginBottom: '-1.2em'}}>
                                <small style={{ color: isDarkMode ? 'white' : 'black', marginBottom: '1em'}}>Turn the lights {isDarkMode ? 'on' : 'off'}</small>                 
                            </div>
                            <br />
                            <Switch 
                                onChange={toggleDarkMode} 
                                checked={isDarkMode} 
                                offColor="#00bafa" 
                                onColor="#00bafa" 
                                uncheckedIcon={<FaRegLightbulb style={{ color: "#fff", paddingLeft: 10, marginTop: '0.45em', marginBottom: 'auto' }} />}
                                checkedIcon={<FaLightbulb style={{ color: "#fff", paddingLeft: 3, marginTop: '0.45em', marginBottom: 'auto', marginRight: '-1em' }} />}
                                handleDiameter={28}
                                offHandleColor="#00bafa"
                                onHandleColor="#00bafa"
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={30}
                                width={60}
                            />
                        </div>
                        <div style={{marginTop: '1em', marginBottom: '2em'}}>
                            <small style={{ color: isDarkMode ? 'white' : 'black', fontSize: "0.75em" }}>*If you want to see more detailed data and statistics head over to </small>
                            <a href="https://frenfren.pro/" className={isDarkMode ? "customLink dark-mode" : "customLink"} style={{fontSize: "0.8em"}}>frenfren.pro <FaExternalLinkAlt size={5} /></a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Footer;

